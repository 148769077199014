@import "~bulma/sass/utilities/initial-variables.sass";

$black: #080808;
$text-black: #000000;
$white: #ffffff;
$second-white: #fbfbfb;
$text-white: #f0f0f0;
$grey: #9a9898;
$dark-grey: #454544;
$grey-deactive: #f3f3f3;
$light-grey: #c1c0c0;
$lighter-grey: #d8d8d8;

$green: #8ab832;
$blue: #172982;
$light-blue: #077cc0;
$red: #d33215;
$orange: #f3921f;
$light: #f0f0f0;

$primary: $blue;

$menu-width: 200px;

$navbar-height: 5rem;

$tabs-border-bottom-width: 2px;
$tabs-border-bottom-color: #ffffff00;
$tabs-link-active-border-bottom-width: 2px;
// $tabs-link-active-border-bottom-color: $light-blue;

$footer-background-color: $black;
$footer-color: $white;

@import "~bulma/bulma.sass";
@import "~bulma-extensions/bulma-pageloader/dist/css/bulma-pageloader.sass";
@import "~bulma-extensions/bulma-divider/dist/css/bulma-divider.sass";
@import "~bulma-switch/src/sass/index.sass";
@import "~@creativebulma/bulma-tooltip/src/sass/index.sass";

html {
  overflow-x: hidden;
}

html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
  padding-top: 4rem;
}

body,
button,
input,
optgroup,
select,
textarea {
  color: #363636;
  font-family: "Roboto", sans-serif;
}

.fileError {
  border-color: #d33215;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.flexG0 {
  flex-grow: 0;
}

.break-word {
  word-break: break-word;
}

.break-all {
  word-break: break-all;
}

.navbar {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  min-height: 4rem;

  .navbar-brand,
  .navbar-tabs,
  .container {
    min-height: 4rem;
  }

  .navbar-burger {
    height: 4rem;
  }

  .navbar-burger {
    font-size: 1.5rem;
    color: $text-white;
  }

  .icon-active {
    background-color: #f2f2f2;
  }

  .navbar-item {
    flex-basis: max-content;
    font-size: 20px;
    border-radius: 4px;

    &:hover {
      background-color: #f2f2f2;
      color: $black;
    }

    .icon {
      font-size: 1.75rem;
      min-width: 5rem;
    }

    img {
      max-height: 5rem;
    }

    .logo {
      height: 40px;
      width: auto;
    }

    &.is-hoverable:focus .navbar-dropdown,
    &.is-hoverable:focus .navbar-dropdown.is-boxed,
    &.is-hoverable:focus-within .navbar-dropdown.is-boxed {
      opacity: 0;
    }
  }
}

.popover {
  font-size: 18px;
  width: 20rem;

  .profile {
    width: calc(100% - 1.5rem - 4rem);
    margin-left: 1.5rem;

    p {
      @extend .truncate;
    }
  }

  .line {
    padding: 1rem;
    cursor: pointer;

    &:hover {
      background-color: $light-blue;
      color: $white;
    }
  }

  img {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    border-radius: 50%;
  }

  .logout {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
    color: $red;

    &:hover {
      background-color: lighten($red, 20);
      color: $white;
    }
  }

  .media {
    .media-content {
      overflow: hidden;
    }
  }
}

.card {
  .card-content {
    padding: 0.75rem;
  }

  .media {
    .media-content {
      word-wrap: break-word;
      word-break: break-all;
    }

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }
}

.modal-card {
  .modal-card-title {
    font-size: 1.2rem;
  }
}

.is-center {
  display: flex;
  align-items: center;
}

.image {
  &.has-preview {
    cursor: pointer;
  }
}

.modal-card-foot {
  &.is-pulled-right {
    justify-content: flex-end;
  }
}

.verify-form {
  .field .field {
    margin-bottom: 0;
  }

  .file-name {
    &.is-loading {
      position: relative;
      pointer-events: none;
      opacity: 0.5;

      &:after {
        @include loader;
        border-color: transparent transparent #454544 #454544 !important;
        position: absolute;
        top: calc(50% - (1em * 0.5));
        right: 1em;
      }
    }
  }
}

.avatar {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.header {
  font-size: 34px;
}

.h-full {
  height: 100%;
}

// button only icon
.button .icon:first-child:last-child,
.button .icon.is-only-child {
  margin-left: calc(-0.5em - 1px) !important;
  margin-right: calc(-0.5em - 1px) !important;
}

.button .icon:first-child:not(:last-child):not(.is-only-child) {
  margin-left: calc(-0.5em - 1px) !important;
  margin-right: 0.25em !important;
}

.button .icon:last-child:not(:first-child):not(.is-only-child) {
  margin-right: calc(-0.5em - 1px) !important;
  margin-left: 0.25em !important;
}

.gallery {
  .gallery-item {
    background-color: $white;
    cursor: pointer;
    // border: 0.0625rem solid #45454461;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }

    .artwork-image {
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      overflow: hidden;
    }

    .content {
      padding-top: 10px;

      .media-content {
        overflow: hidden;
      }

      .artwork-title {
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 1.5rem;
        color: $black;

        .icon-text {
          flex-wrap: nowrap;
        }
      }

      .artwork-brief {
        font-size: 14px;
        color: $grey;
        @extend .truncate;
      }

      .collections {
        font-size: 14px;
        color: $grey;
        height: 1.5rem;
        @extend .truncate;

        &>*+* {
          margin-left: 0.5rem;
        }
      }

      .nfts {
        font-weight: 500;
        color: $black;
      }

      .avatar {
        border-radius: 50%;
        box-shadow: $grey 0 0 2px 0;
        overflow: hidden;
      }

      .author {
        display: flex;
        align-items: center;
        color: $black;
        font-weight: 500;
        white-space: nowrap;

        &>*+* {
          margin-left: 0.5rem;
        }
      }

      .author-avatar {
        flex-shrink: 0;

        img {
          box-shadow: $grey 0 0 2px 0;
        }
      }

      .author-name {
        font-size: 14px;
        color: $dark-grey;
      }
    }

    .gallery-checkbox {
      width: 1.125rem;
      height: 1.125rem;
      margin-left: 0.5rem;
    }
  }
}

.corner-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: white;
  border-bottom-left-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  background-color: $white;
  padding: 3rem 0;
  max-width: 880px;
  min-height: 500px;
  margin: 0 auto;
}

.artwork-thumbnail {
  width: auto;
  max-height: 500px;

  img {
    object-fit: contain;
  }
}

.artwork-page {
  background-color: $white;
  // padding: 3rem 0;
  max-width: 880px;
  margin: 0 auto;

  .icons {
    font-size: 1.5rem;
  }

  .thumbnail {
    width: auto;
    max-height: 500px;

    img {
      object-fit: contain;
    }
  }

  .blockchain {
    .header-container {
      margin-top: 32px;
    }

    .header {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .transferred-container {
      margin-top: 32px;
    }
  }

  .artwork-section {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;

    &:not(:last-child) {
      border-bottom: 0.5px solid $light-grey;
    }

    .header {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
}

.dropzone {
  margin-bottom: 2rem;

  .drop-container {
    height: 37.5rem;
    border: 2px dashed $primary;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    img {
      height: 100%;
      width: auto;
    }

    .drop-message {
      font-weight: 500;
      font-size: 28px;
    }
  }
}

.dropzone-2 {
  .base {
    background-color: $second-white;
    border: 2px dashed $grey;
    color: $grey;
    display: flex;
    height: 21rem;
    outline: none;
    text-align: center;
    transition: border 0.25s ease-in-out;

    &.error {
      border-color: $red;
    }
  }

  .empty {
    justify-content: center;
    align-items: center;
  }

  .accept {
    border-color: $primary;
  }

  .reject {
    border-color: $red;
  }

  .loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0000007f;
    }

    &:after {
      @include loader;
      position: absolute;
      top: calc(50% - 2em);
      left: calc(50% - 2em);
      width: 4em;
      height: 4em;
      border-width: 0.25em;
    }
  }

  .thumbs-container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    overflow-y: scroll;

    align-items: flex-start;
    justify-content: flex-start;

    .thumbnail {
      aspect-ratio: 1;
      display: inline-flex;
      padding: 0.125rem;

      width: calc(100% / 8 - 4px);
      position: relative;
      justify-content: center;

      border: 1px solid $light-grey;
      box-sizing: border-box;

      margin: 2px;

      img {
        height: 100%;
        width: auto;
        object-fit: contain;
      }

      .corner-icon {
        @extend .corner-icon;

        width: 32px;
        height: 32px;
        top: -1px;
        right: -1px;

        background-color: #ffffff8f;
        border-radius: 2px;
        border: 1px solid $light-grey;

        &:hover {
          box-shadow: rgb(0 0 0 / 15%) -1px 1px 2.6px;
          background-color: #ffffff;
          border-color: $grey;
        }
      }
    }
  }
}

.profile-page {
  .avatar-container {
    position: relative;

    .btn-icon {
      position: absolute;
      bottom: 0;
      right: 0;

      border-radius: 50%;

      background-color: $lighter-grey;
      color: $black;
    }

    & :hover {
      color: $light-blue;
      border-color: $light-blue;
    }

    .btn-left {
      left: 0;
      right: auto;
    }
  }

  .user-avatar {
    width: 8rem !important;
    height: 8rem !important;
    border: 1px solid lightgrey;
    border-radius: 50%;
  }

  .profile-name {
    margin: 1rem auto 1rem auto;
    text-align: center;

    .name-editor {
      position: relative;

      span {
        white-space: pre;
        visibility: hidden;
      }

      input {
        top: 5px;
        left: 0;
        width: 100%;
        padding: 0;
        position: absolute;
        display: inline-block;
        border: none;
        text-align: center;
      }
    }
  }
}

.tabs {
  font-size: 24px;

  ul {
    flex-wrap: wrap;
    max-width: 100%;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

.pre-line {
  white-space: pre-line;
}

.round {
  border-radius: 50%;
}

.height-18 {
  height: 18rem;
}

.max-height-18 {
  max-height: 18rem;
  overflow-y: auto;
}

.min-height-16 {
  min-height: 80px;
}

.photo-provider-overlay {
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;

  background-color: #00000080;
  transition: opacity 0.2s ease-out;
  width: 100%;
  z-index: 20;
}

.border {
  border: 0.5px solid $light-grey;
}

.certificate {
  .certificate-header {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  .certificate-content {
    max-width: 400px;
    margin: 0 auto;
  }
}

.contact-message {
  .textarea {
    border: 0;
    padding: 0;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }
}

.background-image {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-image: url("../../public/logo/watermark.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.2;
  z-index: -1000;
}

.gallery-swiper {
  padding: 1rem 0 !important;
  margin: 0 !important;

  .swiper-button-next,
  .swiper-button-prev {
    opacity: 0.8;
    width: 40px;
    height: 40px;
    background-color: $white;
    border-radius: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    &:hover {
      opacity: 1;
    }

    &::before {
      content: " ";
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
    }

    &::after {
      content: unset !important;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .swiper-button-prev {
    left: 0;

    &::before {
      font-family: unset !important;
      font-size: 0 !important;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMy42NDY0NSAxMS42NDY0QzMuNDUxMTkgMTEuODQxNyAzLjQ1MTE5IDEyLjE1ODMgMy42NDY0NSAxMi4zNTM2TDYuODI4NDMgMTUuNTM1NUM3LjAyMzY5IDE1LjczMDggNy4zNDAyNyAxNS43MzA4IDcuNTM1NTMgMTUuNTM1NUM3LjczMDggMTUuMzQwMyA3LjczMDggMTUuMDIzNyA3LjUzNTUzIDE0LjgyODRMNC43MDcxMSAxMkw3LjUzNTUzIDkuMTcxNTdDNy43MzA4IDguOTc2MzEgNy43MzA4IDguNjU5NzMgNy41MzU1MyA4LjQ2NDQ3QzcuMzQwMjcgOC4yNjkyIDcuMDIzNjkgOC4yNjkyIDYuODI4NDMgOC40NjQ0N0wzLjY0NjQ1IDExLjY0NjRaTTIwIDExLjVMNCAxMS41VjEyLjVMMjAgMTIuNVYxMS41WiIgZmlsbD0iIzE3Mjk4MiIvPjwvc3ZnPgo=") !important;
    }
  }

  .swiper-button-next {
    right: 0;

    &::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjAuMzUzNiAxMi4zNTM2QzIwLjU0ODggMTIuMTU4MyAyMC41NDg4IDExLjg0MTcgMjAuMzUzNiAxMS42NDY0TDE3LjE3MTYgOC40NjQ0N0MxNi45NzYzIDguMjY5MiAxNi42NTk3IDguMjY5MiAxNi40NjQ1IDguNDY0NDdDMTYuMjY5MiA4LjY1OTczIDE2LjI2OTIgOC45NzYzMSAxNi40NjQ1IDkuMTcxNTdMMTkuMjkyOSAxMkwxNi40NjQ1IDE0LjgyODRDMTYuMjY5MiAxNS4wMjM3IDE2LjI2OTIgMTUuMzQwMyAxNi40NjQ1IDE1LjUzNTVDMTYuNjU5NyAxNS43MzA4IDE2Ljk3NjMgMTUuNzMwOCAxNy4xNzE2IDE1LjUzNTVMMjAuMzUzNiAxMi4zNTM2Wk00IDEyLjVIMjBWMTEuNUg0VjEyLjVaIiBmaWxsPSIjMTcyOTgyIi8+PC9zdmc+Cg==") !important;
    }
  }
}

.footer {
  img {
    width: 192px;
  }

  a {
    display: block;
    color: $white;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;

    @include mobile {
      text-align: center;
    }
  }
}

.explorer-header {
  display: flex;

  .item {
    font-size: 2rem;
    padding: 0 1rem;

    @include mobile {
      font-size: 1rem;
      padding: 0 0.5rem;
    }

    &.is-active {
      font-weight: 600;
      border-bottom: 3px solid $primary;
    }
  }
}

.nft-item {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .series {
    font-size: 14px;
    margin-top: 4px;
    margin-left: 20px;

    &>*+* {
      margin-left: 0.5rem;
    }
  }

  .transfer-btn {
    min-width: 40px;
  }

  .owner {
    display: flex;
    align-items: center;
    margin-top: 6px !important;
    margin-left: 20px;

    .image {
      width: 24px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: $grey 0 0 2px 0;
        overflow: hidden;
      }
    }

    .name {
      margin-left: 8px;
      font-size: 14px;
    }
  }
}

.collection-item {
  align-items: center;

  .media-content {
    overflow: hidden;
  }

  .name {
    font-weight: 500;
  }

  .description {
    font-size: 14px;
    color: $grey;
    @extend .truncate;
  }
}

.attachments {
  .attach-item {
    .image {
      box-shadow: $grey 0 0 2px 0;
    }

    .name {
      font-weight: 500;
    }

    .description {
      font-size: 14px;
      color: $grey;
      @extend .truncate;
    }
  }
}


.ribbon {
  position: absolute;
  background: #08769b;
  box-shadow: 0 0 0 999px #08769b;
  clip-path: inset(0 -100%);
  inset: 0 auto auto 0;
  transform-origin: 100% 0;
  transform: translate(-29.3%) rotate(-45deg);
}

.view-all {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.placeholder-100 {
  width: 100%;
  height: 20px;
  background-color: $light-grey;
  border-radius: 4px;
}

.placeholder-75 {
  width: 75%;
  height: 20px;
  background-color: $light-grey;
  border-radius: 4px;
}

.placeholder-50 {
  width: 50%;
  height: 20px;
  background-color: $lighter-grey;
  border-radius: 4px;
}

.pdf-page {
  border: 1px solid $light-grey;
  margin-bottom: 4px;
  overflow: hidden;
  user-select: none;
  width: 100% !important;
  min-width: 100% !important;

  canvas {
    width: 100% !important;
    height: auto !important;
  }
}

.settings {
  .field-label {
    flex-grow: 2;
  }
}
